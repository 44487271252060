<script setup>
import { inject } from "vue";

const logout = inject("logout");

const menuItems = [
  {
    onClick: logout,
    title: "Çıkış Yap",
    type: "icon",
    name: "log-out",
  },
];
</script>

<template>
  <a-tooltip
    v-for="(item, index) in menuItems"
    :key="index"
    :title="item.title"
    placement="right"
  >
    <a
      @click="item.onClick"
      class="chat-side-menu-item"
      :class="item.itemClass || ''"
    >
      <img v-if="item.type === 'image'" :src="item.src" height="45" />
      <i
        v-else-if="item.type === 'icon'"
        class="bx chat-side-menu-item-icon font-size-180"
        :class="`bx-${item.name}`"
      ></i>
    </a>
  </a-tooltip>
  <!-- 
      <box-icon
        
        class="chat-side-menu-item-icon"
        color="var(--dark-mode-gray)"
        size="30px"
        :name="item.name"
      ></box-icon>

  -->
</template>
