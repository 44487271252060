<script setup>
import { useUserStore } from "@/stores/user.store";
import { useRouter } from "vue-router";
import { computed } from "vue";

const router = useRouter();
const userStore = useUserStore();

const storedUser = computed(() => ({
  avatar: userStore.getAvatar,
}));

const menuItems = computed(() => [
  {
    onClick: refreshPage,
    title: "Sayfayı Yenile",
    type: "image",
    src: "/512px-favicon.png",
    itemClass: "bg-transparent",
    tooltip: true,
  },
  {
    type: "avatar",
    title: "Sen",
    src: storedUser.value.avatar,
    itemClass: "bg-transparent cursor-help",
    tooltip: false,
    onClick: () => {},
  },
]);

const refreshPage = () => {
  router.go(0);
};
</script>

<template>
  <a-tooltip
    v-for="(item, index) in menuItems"
    :key="index"
    :title="item.title"
    placement="right"
  >
    <a
      @click="item.onClick"
      class="chat-side-menu-item"
      :class="item.itemClass"
    >
      <img v-if="item.type === 'image'" :src="item.src" height="45" />
      <a-avatar
        v-else-if="item.type === 'avatar'"
        :src="$cdnAvatarUrl + item.src"
        :size="{ xs: 45, sm: 45, md: 45, lg: 45, xl: 45, xxl: 45 }"
      ></a-avatar>
    </a>
  </a-tooltip>
</template>
